<template>
  <section class="pa-3">
    <h2 class="mb-10">CSVダウンロード</h2>

    <section class="bulk__upload_area col-7 mb-4">
      <v-btn rounded outlined @click="csvDownload">
        写真情報をCSVで一括ダウンロードする
      </v-btn>
    </section>
  </section>
</template>

<script>
import { getData } from "../../axios";
import Papa from "papaparse";
import { saveAs } from "file-saver";

export default {
  name: "Index",
  methods: {
    csvDownload() {
      getData("photos")
        .then((res) => {
          const csvData = res;
          const csvArray = [
            [
              "タイトル",
              "メディア",
              "説明",
              "メモ",
              "場所",
              "撮影者",
              "権利者",
              "キーワード",
              "緯度",
              "経度",
              "緯度と経度をオープンデータとして公開する",
              "撮影日時",
              "ライセンス",
              "ライセンス自由入力",
              "ファイル名",
              "画像の幅",
              "画像の高さ",
              "写真ID",
              "ステータス",
              "カテゴリ",
            ],
          ];
          Array.prototype.forEach.call(csvData.data, (v) => {
            csvArray.push([
              v.title,
              v.video ? "video" : "photo",
              v.description,
              v.memo,
              v.place,
              v.photographer,
              v.owner,
              v.tags.map((t) => t.label).join(" | "),
              v.latitude,
              v.longitude,
              v.enable_location_opendata,
              v.shot_at,
              v.license.slug,
              v.license_description,
              v.image.replace("/thumbnail/", "/original/"),
              v.image_width,
              v.image_height,
              v.id,
              v.status === 'draft' ? 'unpublished' : 'published',
              v.categories.map((c) => c.name).join(" | "),
            ]);
          });
          const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
          saveAs(
            new Blob([bom, Papa.unparse(csvArray)], {
              type: "text/csv;charset=utf-8",
            }),
            "photos.csv"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
